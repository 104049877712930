import React from "react";
import logowallStyles from "./logowall.module.css";

import logoGatsby from "../assets/img/tech-logos/gatsby.jpg";
import logoFlutter from "../assets/img/tech-logos/flutter.jpg";
import logoReact from "../assets/img/tech-logos/react.jpg";
import logoOffice365 from "../assets/img/tech-logos/office365.jpg";
import logoWordpress from "../assets/img/tech-logos/wordpress.jpg";
import logoFirebase from "../assets/img/tech-logos/firebase.jpg";
import logoJira from "../assets/img/tech-logos/jira.jpg";
import logoJavascript from "../assets/img/tech-logos/javascript.jpg";

// FIXME: utiliser data JSON et foreach pour les logos
// Souci pour charger les images dynamiquement dans la boucle :(
/*
const wallData = [
    {
        tech : "Gastby",
        color : "#6b4495"
    },
    {
        tech : "Flutter",
        color : "#ffffff"
    },
    {
        tech : "React",
        color : "#61DAFB"
    },
    {
        tech : "Office365",
        color : "#F3F3F3"
    }
];
{wallData.map((logo, i) => <div key={i} className="col-3 col-6-sm logo-wrapper" style={{ backgroundColor: logo.color }}>
    <img src={logo + logo.tech} alt="{logo.tech} logo" />
    </div> 
)}
*/
export default function LogoWall() {
  return (
    <section className={logowallStyles.logoswall}>
      <div className="container">
        <div className="row">
          <div
            className="col-3 col-6-sm .logowrapper"
            style={{ backgroundColor: "#6b4495" }}
          >
            <figure>
              <img src={logoGatsby} alt="Gatsby.js logo" />
              <figcaption>Gatsby.js</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <figure>
              <img src={logoFlutter} alt="Flutter logo" style={{}} />
              <figcaption>Flutter</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#61DAFB" }}
          >
            <figure>
              <img src={logoReact} alt="React logo" style={{}} />
              <figcaption>React</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#F3F3F3" }}
          >
            <figure>
              <img src={logoOffice365} alt="Office 365 logo" style={{}} />
              <figcaption>Suite Office 365</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <figure>
              <img src={logoWordpress} alt="Wordpress logo" style={{}} />
              <figcaption>Wordpress</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#2C394B" }}
          >
            <figure>
              <img src={logoFirebase} alt="Firebase logo" style={{}} />
              <figcaption>Outils Firebase</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <figure>
              <img src={logoJira} alt="Jira logo" style={{}} />
              <figcaption>Jira, Confluence</figcaption>
            </figure>
          </div>
          <div
            className="col-3 col-6-sm logo-wrapper"
            style={{ backgroundColor: "#F7DF1E" }}
          >
            <figure>
              <img src={logoJavascript} alt="Javascript logo" style={{}} />
              <figcaption>Javascript</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
